const ShoppingMobileIcon = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.6875"
        y="0.6875"
        width="42.625"
        height="42.625"
        rx="21.3125"
        fill="#F3F3F3"
      />
      <rect
        x="0.6875"
        y="0.6875"
        width="42.625"
        height="42.625"
        rx="21.3125"
        stroke="#E8E9E9"
        strokeWidth="1.375"
      />
      <mask
        id="mask0_15474_61976"
        maskUnits="userSpaceOnUse"
        x="5"
        y="5"
        width="34"
        height="34"
      >
        <rect x="5.5" y="5.5" width="33" height="33" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_15474_61976)">
        <path
          d="M14.1731 35.0624C13.4786 35.0624 12.8907 34.8218 12.4094 34.3406C11.9282 33.8593 11.6875 33.2714 11.6875 32.5768V16.9231C11.6875 16.2285 11.9282 15.6406 12.4094 15.1594C12.8907 14.6781 13.4786 14.4375 14.1731 14.4375H16.8438V14.0938C16.8438 12.6694 17.3471 11.4539 18.3536 10.4474C19.3602 9.44079 20.5756 8.9375 22 8.9375C23.4244 8.9375 24.6398 9.44079 25.6464 10.4474C26.6529 11.4539 27.1562 12.6694 27.1562 14.0938V14.4375H29.8269C30.5214 14.4375 31.1094 14.6781 31.5906 15.1594C32.0719 15.6406 32.3125 16.2285 32.3125 16.9231V32.5768C32.3125 33.2714 32.0719 33.8593 31.5906 34.3406C31.1094 34.8218 30.5214 35.0624 29.8269 35.0624H14.1731ZM14.1731 33H29.8269C29.9327 33 30.0296 32.9559 30.1178 32.8678C30.2059 32.7796 30.25 32.6826 30.25 32.5768V16.9231C30.25 16.8173 30.2059 16.7203 30.1178 16.6322C30.0296 16.544 29.9327 16.5 29.8269 16.5H27.1562V19.5938C27.1562 19.8864 27.0575 20.1314 26.8601 20.3288C26.6627 20.5263 26.4176 20.625 26.125 20.625C25.8324 20.625 25.5874 20.5263 25.3899 20.3288C25.1925 20.1314 25.0938 19.8864 25.0938 19.5938V16.5H18.9062V19.5938C18.9062 19.8864 18.8075 20.1314 18.6101 20.3288C18.4127 20.5263 18.1676 20.625 17.875 20.625C17.5824 20.625 17.3374 20.5263 17.1399 20.3288C16.9425 20.1314 16.8438 19.8864 16.8438 19.5938V16.5H14.1731C14.0673 16.5 13.9704 16.544 13.8822 16.6322C13.7941 16.7203 13.75 16.8173 13.75 16.9231V32.5768C13.75 32.6826 13.7941 32.7796 13.8822 32.8678C13.9704 32.9559 14.0673 33 14.1731 33ZM18.9062 14.4375H25.0938V14.0938C25.0938 13.2317 24.7937 12.5006 24.1934 11.9004C23.5932 11.3001 22.862 11 22 11C21.138 11 20.4068 11.3001 19.8066 11.9004C19.2064 12.5006 18.9062 13.2317 18.9062 14.0938V14.4375Z"
          fill="#323941"
        />
      </g>
    </svg>
  );
};
export default ShoppingMobileIcon;
